.spotlight {
  position: relative;

  &:hover {
    .spotlight {
      &__swiper-container {
        .swiper-button-prev,
        .swiper-button-next {
          @media screen and (min-width: 768px) {
            display: block !important;
          }
        }
      }
    }
  }

  &__inner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%);
    height: 100%;
    width: 100%;

    @media screen and (min-width: 768px) {
      padding-bottom: 115px;
    }
  }


  &__inner-white {
    background: transparent;
    opacity: 1;
    height: 100%;
    width: 100%;

    @media screen and (min-width: 768px) {
      padding-bottom: 115px;
    }
  }

  &__badge {
    background: #eb0015;
    font-style: normal;
    font-weight: 500;
    color: #ffffff;
    padding: 3px 5px;
    margin-bottom: 10px;
    border-radius: 4px;

    font-size: 12px;
    line-height: 23px;

    @media screen {
      padding: 5px 10px;
      font-size: 20px;
      line-height: 23px;
    }
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
    letter-spacing: 1px;

    @media screen and (min-width: 768px) {
      font-size: 35px;
      line-height: 35px;
    }

    @media screen and (min-width: 992px) {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.4px;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 10px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 35px;
      line-height: 35px;
    }

    @media screen and (min-width: 1366px) {
      font-size: 41px;
      line-height: 41px;
    }

    @media screen and (min-width: 1440px) {
      font-size: 64px;
      line-height: 64px;
    }

    @media screen and (min-width: 1536px) {
      font-size: 68px;
      line-height: 68px;
    }
  }

  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
    letter-spacing: -0.8px;
    color: #ffffff;
    margin-bottom: 50px;

    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media screen and (min-width: 992px)
    {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.4px;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media screen and (min-width: 1200px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media screen and (min-width: 1366px) {
    }

    @media screen and (min-width: 1440px) {
      font-size: 24px;
      line-height: 26px;
    }

    @media screen and (min-width: 1536px) {
      font-size: 26px;
      list-style: 28px;
    }
  }

  &__thumb {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }

    .swiper-container {
      position: absolute;
      bottom: 0;
    }
  }

  &__thumb-content {
    text-align: left;
    padding-right: 20px;
    padding-left: 20px;
  }

  // .swiper {
  //   width: 100%;
  //   height: 100%;
  // }

  &__slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__thumb-image {
    border-radius: 4px;
    margin-right: 10px;
    width: 107px;
  }

  &__thumb-slide {
    cursor: pointer;
    pointer-events: all;
    background-color: transparent;
    width: 25%;
    height: 114px;

    color: #fff;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      color: #000;
      background-color: #ffffff;
    }
  }

  .swiper-slide__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // .swiper {
  //   width: 100%;
  //   height: 300px;
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .swiper-slide__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__swiper-container {
    width: 100%;
    height: 312px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 768px) {
      height: 469px;

      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }
    }

    @media screen and (min-width: 992px) {
      height: 466px;
    }

    @media screen and (min-width: 992px) {
      height: 466px;
    }

    @media screen and (min-width: 1200px) {
      height: 481px;
    }

    @media screen and (min-width: 1366px) {
      height: 523px;
    }

    @media screen and (min-width: 1440px) {
      height: 673px;
    }
  }

  &__thumbs-container {
    width: 100%;
    display: none;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 114px;

    @media screen and (min-width: 768px) {
      display: block;
    }

    .swiper-slide-thumb-active {
      border-top: solid 3px #fff;
    }
  }
}

.spotlight-thumbs {
  padding-left: 100px;
  padding-right: 100px;
  background: rgba(0, 0, 0, 0.48);
}
